.role-switch-container {
  display: flex;
  flex-direction: column;

  .each-switch {
    display: flex;
    justify-content: space-between;
  }
}

.role-label {
  color: #8c8c8c;
}

.check-option {
  margin-top: 5px;
}
